import { IoMdQrScanner } from 'react-icons/io'

import { Icon } from '@chakra-ui/react'

export const QuickReportIcon = () => (
  <Icon
    as={IoMdQrScanner}
    backgroundColor='brand.100'
    bgGradient='linear(to-t, brand.100, brand.200)'
    borderRadius='base'
    boxSize='30px'
    color='white'
    padding='3px'
  />
)
