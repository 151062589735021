import {
  IncidentsQueryHookResult,
  IncidentsQueryResult,
} from '@/graphql/generated/hooks'
import { DeviceType, IncidentSource } from '@/graphql/generated/schemas'

export type RefetchIncidentsFnType = IncidentsQueryHookResult['refetch']

export type IncidentsListType =
  IncidentsQueryResult['data']['incidents']['edges']

export enum ReportSource {
  Other,
  QuickReport,
}

export type IncidentOrigin = DeviceType | IncidentSource | ReportSource
