export const STRING_REGEX = /^[a-zA-Z0-9_\s('!&@/$"`'‘’“”´)-=:]*$/
export const PNG_MIME_TYPE = 'image/png'

export const maxLength = (value: number) => ({
  value,
  message: `Must be less than ${value} characters`,
})

export const isValidFileType = (fl: FileList): boolean => {
  if (fl?.length === 0) {
    return true
  }
  return fl?.[0]?.type === PNG_MIME_TYPE
}

export const MAX_QUICK_REPORT_NAME_CHARACTERS = 32
