import { InputGroup } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { ComboboxList, ComboboxOption, ComboboxPopover } from '@reach/combobox'

export const StyledInputGroup = styled(InputGroup)`
  .chakra-input {
    font-weight: 600;
    font-size: 16px;
    color: #000;

    ::placeholder {
      font-size: 14px;
      color: #2d2e41;
      opacity: 30%;
      font-style: italic;
      font-weight: 500;
    }
  }
`

export const StyledComboboxList = styled(ComboboxList)`
  font-weight: 600;
  font-size: 14px;
  max-height: 200px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
`

export const StyledComboboxOption = styled(ComboboxOption)`
  padding: 8px 8px;
  padding-left: 16px;
  border-bottom: 1px solid rgba(9, 30, 66, 0.14);
`

export const StyledComboboxPopover = styled(ComboboxPopover)`
  border-radius: 6px;
  border: 2px solid #9fb8e5;
`
