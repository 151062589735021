import { FiUpload } from 'react-icons/fi'

import { Button } from '@/components/ui'

import { ButtonIProps } from '../Button/Button'

interface FileAttachButtonIProps {
  placeholder?: string
  onFileAttach: () => void
  variant?: ButtonIProps['variant']
}

export const FileAttachButton = ({
  placeholder,
  onFileAttach,
  variant,
}: FileAttachButtonIProps) => (
  <Button
    leftIcon={<FiUpload size={24} />}
    onClick={onFileAttach}
    variant={variant}
    width='100%'
  >
    {placeholder}
  </Button>
)
