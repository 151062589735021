import { HiOutlineCheckCircle } from 'react-icons/hi'

import {
  Input as ChakraInput,
  Icon,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
} from '@chakra-ui/react'

export interface InputIProps extends InputProps {
  /**
   * Input name.
   */
  name?: string
  /**
   * If `true`, the input will display valid state styles with check icon.
   */
  valid?: boolean
  /**
   * If added, the button will show an icon to the left of the input.
   */
  leftIcon?: React.ElementType
  /**
   * If `true`, the input will display error state styles.
   */
  isInvalid?: boolean
  /**
   * The placeholder value for this input.
   */
  placeholder?: string
  /**
   * The width of the input element.
   */
  width?: string
  /**
   * The variant used to style the input, default is 'outline'
   */
  variant?: 'outline' | 'v2'
  [key: string]: unknown
}

export const Input = ({
  name,
  isInvalid,
  placeholder,
  leftIcon,
  valid,
  inputRef,
  width = '100%',
  variant = 'outline',
  ...rest
}: InputIProps) => {
  const dataTestId = rest.dataTestId || 'text-input'

  const getBorderColor = () => {
    if (variant === 'v2') return null
    return valid ? '#2BD2B4' : '#9FB8E5'
  }
  return (
    <InputGroup width={width}>
      {leftIcon && (
        <InputLeftElement
          children={<Icon as={leftIcon} boxSize={4} color='#33344B' />}
          data-testid='left-input-icon'
        />
      )}
      <ChakraInput
        autoCapitalize='off'
        autoComplete='off'
        autoCorrect='off'
        bgColor='white'
        borderColor={getBorderColor()}
        data-testid={dataTestId}
        errorBorderColor='#D01030'
        isInvalid={isInvalid}
        name={name}
        placeholder={placeholder}
        ref={inputRef as React.RefObject<HTMLInputElement>}
        spellCheck='false'
        variant={variant}
        {...rest}
      />
      {valid && (
        <InputRightElement
          children={
            <Icon as={HiOutlineCheckCircle} boxSize={6} color='#2BD2B4' />
          }
          data-testid='input-valid-icon'
        />
      )}
    </InputGroup>
  )
}
