import { ChangeEventHandler, useRef, useState } from 'react'

import {
  FormControl as ChakraFormControl,
  FormControlOptions,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/react'

import { ButtonIProps } from '../Button/Button'
import { FileAttachButton } from './FileAttachButton'
import { FileUploadInput } from './FileUploadInput'

export interface FormInputControlIProps extends FormControlOptions {
  id: string
  label: string
  accept: string
  inputRef: (ref: HTMLInputElement) => void
  onFileRemoved?: () => void
  isInvalid?: boolean
  isDisabled?: boolean
  errorMessage?: string
  width?: string
  placeholder?: string
  [key: string]: unknown
  variant?: ButtonIProps['variant']
}

export const FormFileUploadControl = ({
  id,
  label,
  accept,
  isInvalid = false,
  isDisabled = false,
  errorMessage,
  width = '100%',
  placeholder = 'Upload file',
  inputRef,
  onFileRemoved,
  variant,
  ...rest
}: FormInputControlIProps) => {
  const [file, setFile] = useState(null)
  const customRef = useRef<HTMLInputElement | null>(null)
  const hasFile = file !== null

  const onFileChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    // This will cause rerender when new file is uploaded
    setFile(e.target.value)
  }

  const onFileRemove = () => {
    if (customRef?.current) {
      // Reset attached file
      customRef.current.value = null
      // Need to manually set file, because onFileChange will not be triggered
      setFile(null)
      onFileRemoved?.()
    }
  }

  const onFileAttach = () => {
    // Clicks hidden input
    customRef?.current.click?.()
  }

  return (
    <ChakraFormControl isInvalid={isInvalid} width={width}>
      <FormLabel color='#2d2e41' fontSize='16px' htmlFor={id} opacity={0.5}>
        {label}
      </FormLabel>
      <input
        accept={accept}
        data-testid='FormFileUploadControl:input'
        disabled={isDisabled}
        hidden
        id={id}
        multiple={false}
        name={id}
        onChange={onFileChange}
        ref={(el) => {
          inputRef(el)
          customRef.current = el
        }}
        type='file'
        {...rest}
      />
      {hasFile ? (
        <FileUploadInput
          file={customRef?.current?.files?.[0]}
          isDisabled={isDisabled}
          isInvalid={isInvalid}
          onFileRemove={onFileRemove}
        />
      ) : (
        <FileAttachButton
          onFileAttach={onFileAttach}
          placeholder={placeholder}
          variant={variant}
        />
      )}
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </ChakraFormControl>
  )
}
