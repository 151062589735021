import { IncidentOrigin, ReportSource } from '@/features/incidents-table'
import {
  DeviceType,
  IncidentSource,
  IncidentStatus,
} from '@/graphql/generated/schemas'

export const getIncidentStatusColor = (status: IncidentStatus) => {
  switch (status) {
    case IncidentStatus.Resolved:
      return 'incident.resolved.100'
    case IncidentStatus.Active:
      return 'incident.active.100'
    case IncidentStatus.InProgress:
      return 'incident.progress.100'
    default:
      return '#888888'
  }
}

export const getIncidentStatusColorHex = (status: IncidentStatus) => {
  switch (status) {
    case IncidentStatus.Resolved:
      return '#29D11E'
    case IncidentStatus.Active:
      return '#D01030'
    case IncidentStatus.InProgress:
      return '#D16A1E'
    default:
      return '#888888'
  }
}

export const getIncidentStatusDisplayName = (status: IncidentStatus) => {
  switch (status) {
    case IncidentStatus.Resolved:
      return 'Resolved'
    case IncidentStatus.Active:
      return 'Active'
    case IncidentStatus.InProgress:
      return 'In Progress'
    default:
      return 'Unknown'
  }
}
export const getIncidentSourceDisplayName = (source: IncidentOrigin) => {
  switch (source) {
    case ReportSource.QuickReport:
      return 'Quick Report'
    case DeviceType.Camera:
      return 'Camera'
    case DeviceType.Door:
      return 'Door'
    case IncidentSource.Guard:
      return 'Guard'
    default:
      return 'Manual'
  }
}
