export const formLabelStyles = {
  baseStyle: {
    fontSize: 'md',
    marginEnd: 3,
    mb: 2,
    fontWeight: 'medium',
    transitionProperty: 'common',
    transitionDuration: 'normal',
    opacity: 1,
    _disabled: {
      opacity: 0.4,
    },
  },

  variants: {
    v2: {
      fontSize: '12px',
      color: '#7d7d7d',
      fontWeight: 400,
      lineHeight: '18px',
    },
  },
}
